<template>
  <div id="viewCashInLeaveRequestView" class="text-left">
    <div class="row">
      <div class="col">
        <h4>view {{ request.requestType | toLeaveTypeDescription }} entry</h4>
        <transition name="slide-fade">
          <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
        <div>
          <EmployeeInfoDisplay v-if="employeeInfo.employeeId" :employeeInfo="employeeInfo" :vacationYear="vacationYear"></EmployeeInfoDisplay>
        </div>
      </div>
    </div>
    <div class="row" v-if="request.id">
      <div class="col">
        <div v-if="request.id" class="clearfix">
          <div class="float-right">
            <span class="col-md-3">status:</span>
            <span class="font-weight-bold" v-bind:class="{ 'text-danger': request.status == Enums.ChangeRequestStatus.Cancelled.value || request.status == Enums.ChangeRequestStatus.Rejected.value,  'text-success': request.status == Enums.ChangeRequestStatus.Approved.value }">{{ request.status | toRequestStatusDescription }}</span>
          </div>
        </div>
        <div class="form-group row mt-4">
          <span class="col-md-3">request date</span>
          <span class="col-md-6">{{ request.requestDate | toShortDateString }}</span>
        </div>
        <div class="form-group row mt-4">
          <span class="col-md-3">adjustment type</span>
          <span class="col-md-6 font-weight-bold">{{ request.requestType == Enums.RequestType.AdjustmentAddition.value ? 'added vacation days' : 'removed vacation days' }}</span>
        </div>
        <div class="form-group row mt-4">
          <span class="col-md-3">number of vacation days</span>
          <span class="col-md-6">{{ request.numberOfDays }}</span>
        </div>
        <template v-if="request.vacationRequestId">
          <div class="form-group row mt-4">
            <span class="col-md-3">original request</span>
            <span class="col-md-6">
              <router-link v-if="request.vacationRequestId" :to="{ name: 'viewVacationRequest', params: { id: request.vacationRequestId }}">View Associated request</router-link>
            </span>
          </div>
        </template>
        <div class="form-group row mt-4">
          <span class="col-md-3">remarks</span>
          <textarea class="col-md-6 p-2" rows="3" disabled>{{ request.remarks }}</textarea>
        </div>
        <div class="form-group row mt-4" v-if="request.requestType != Enums.RequestType.AdjustmentAddition.value && request.requestType != Enums.RequestType.AdjustmentRemoval.value">
          <span class="col-md-3">approver</span>
          <span class="col-md-6">{{ request.intendedApproverName }}</span>
        </div>
      </div>
    </div>
    <div class="row" v-if="request.id">
      <div class="col">
        <h5>Action/Log History Details</h5>
        <RequestActionList :requestActions="request.requestActions"></RequestActionList>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .error {
    text-align: left;
    color: #ac0c0c;
    list-style: none;
  }

  fieldset {
    border: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

  .spin .spinner {
    left: -.6em;
    top: .4em;
    width: 2.5em;
    display: block;
    position: absolute;
  }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }
</style>

<script>

  import { leaveRequestService } from '@/services'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'

  import Enums from '@/utils/enums'

  import { toRequestStatusDescription, toLeaveTypeDescription } from '@/filters/enum'
  import { toShortDateString } from '@/filters'

  import GeneralMixin from '@/mixins/GeneralMixin'
  import AlertMixin from '@/mixins/AlertMixin'
  import ErrorMixin from '@/mixins/ErrorMixin'

  import AppAlert from '@/components/ui/AppAlert'
  import EmployeeInfoDisplay from '@/components/cashInLieu/ViewEmployeeInfoDisplay'
  import RequestActionList from '@/components/leaveRequests/RequestActionList'


  export default {
    name: 'ViewCashInLieuRequestView',
    mixins: [GeneralMixin, AlertMixin, ErrorMixin],
    components: {
      AppAlert,
      EmployeeInfoDisplay,
      RequestActionList
    },
    data: () => ({
      request: {
        id: null,
        requestDate: null,
        numberOfDays: null,
        status: null,
        remarks: null,
        requestActions: [],
        vacationRequestId: null,
        intendedApproverName: null,
        requestType: ''
      },
      employeeInfo: {
        employeeId: '',
        employeeNo: '',
        fullName: '',
        employeeTypeName: '',
        workScheduleName: '',
        employmentDate: '',
      },
      vacationYear: null,
      Enums: Enums
    }),
    filters: {
      toLeaveTypeDescription,
      toRequestStatusDescription,
      toShortDateString
    },
    methods: {
      fetchExistingData() {
        const self = this
        eventBus.$emit(Events.LongOperationStarted, '')
        leaveRequestService.getLeaveRequest('VacationChange', self.$route.params.id).then((request) => {
          const employee = request.employeeInfo
          self.employeeInfo.employeeId = employee.employeeId
          self.employeeInfo.employeeNo = employee.employeeNo
          self.employeeInfo.fullName = employee.fullName
          self.employeeInfo.employeeTypeName = employee.employeeTypeName
          self.employeeInfo.workScheduleName = employee.workScheduleName
          self.employeeInfo.employmentDate = employee.employmentDate
          self.request.id = request.id
          self.request.requestDate = request.requestDate
          self.request.numberOfDays = request.numberOfDays
          self.request.status = request.status
          self.request.remarks = request.remarks
          self.request.intendedApproverName = request.intendedApproverName
          self.request.vacationRequestId = request.vacationRequestId
          self.request.requestType = request.requestType
          self.vacationYear = request.vacationMaster.vacationYear
          for (const action of request.requestActions) {
            self.request.requestActions.push(action)
          }
        }).catch((error) => {
          self.showErrorMessage(error)
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
        })
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.fetchExistingData()
      })
    }
  }
</script>
